<template>
  <div class="main">
    <yun-header />
    <div class="body">
      <div class="body_left">
        <div class="content">
          <div class="top">
            <div class="top-left" @click="$refs.childDialog.openCommon('过期药品示警')">
              <div class="top-leftbg">54</div>
              <span>过期示警</span>
            </div>
            <div class="top-center">
              <span>智能药箱</span>
            </div>
            <div class="top-left" @click="$refs.childDialog.openCommon('不合格药品示警')">
              <div class="top-leftbg">5</div>
              <span>不合格示警</span>
            </div>
          </div>
          <div class="bottom">
            <div class="bottom-commont" @click="$refs.childDialog.openCommon('服用提醒')">
              <div class="bottom-bg">166</div>
              <span>服用提醒</span>
            </div>
            <div class="bottom-commont" @click="$refs.childDialog.openCommon('药品近效期提醒')">
              <div class="bottom-bg">103</div>
              <span>药品近效期提醒</span>
            </div>
            <div class="bottom-commont">
              <div class="bottom-bg">0</div>
              <span>禁忌提醒</span>
            </div>
          </div>
        </div>
        <div class="yphsjd">
          <div class="item1" style="margin-left:20px">不合格药品数据</div>
          <!-- <div class="item2">药品回收点:</div> -->
          <!-- <div class="item3">{{huiCount}}个</div> -->
        </div>
        <div class="hsBiao" style="margin-top: 20px">
          <table width="100%" cellspacing="0" cellpadding="0">
            <tr class="biaoTitle">
              <td class="txt-c" style="width: 50px">序号</td>
              <td style="width: 150px">药品名称</td>
              <!-- <td style="width: 150px">上市许可持有人</td> -->
              <td style="width: 120px" class="txt-c">生产企业</td>
              <td style="width: 80px" class="txt-c">批号</td>
              <td style="width: 80px" class="txt-c">规格</td>
              <td style="width: 150px" class="txt-c">不符合规定项目</td>
              <td style="width: 80px" class="txt-c">提醒时间</td>
            </tr>
          </table>

          <div style="height:400px;overflow: hidden;">
            <vue-seamless-scroll :data="unqualifiedData" :class-option="defaultOption">
              <table width="100%" cellspacing="0" cellpadding="0">
                <tr class="biaoList" v-for="(x, i) in unqualifiedData" :key="i">
                  <td style="width: 50px;height: 70px" class="txt-c">
                    {{ i + 1 }}
                  </td>
                  <td style="width: 150px">{{ x.name }}</td>
                  <!-- <td style="width: 150px">{{ x.company_place }}</td> -->
                  <td style="width: 180px" class="txt-c">{{ x.company }}</td>
                  <td style="width: 80px" class="txt-c">{{ x.lot }}</td>
                  <td style="width: 80px" class="txt-c">{{ x.spec }}</td>
                  <td style="width: 150px" class="txt-c">{{ x.result }}</td>
                  <td style="width: 80px" class="txt-c">2024-11-22</td>
                </tr>
              </table>
            </vue-seamless-scroll>
          </div>
        </div>
      </div>
      <div class="body_right">
        <div class="rightZhyf">
          <div class="title">
            <!-- <div class="zhonggeng">中埂社区智慧药房</div> -->
            智慧药房
          </div>
          <div class="rightZhyfContent">
            <div class="rightZhyfContentLeft">
              <div class="rightZhyfContentLeft1">
                <div class="rightZhyfContentLeft1Left item" @click="$refs.childDialog.openZhonggeng('本月销售')">
                  <div><span class="num">143</span><span class="ren">笔</span></div>
                  <div class="item">
                    <div>本月销售服务数量</div>
                  </div>
                </div>
                <div class="rightZhyfContentLeft1Right item" @click="$refs.childDialog.openZhonggeng('处方流转')">
                  <div><span class="num">262</span><span class="ren">人</span></div>
                  <div style="margin-left: 0px">
                    <div>三大医院处方流转取药数</div>
                  </div>
                </div>
              </div>
              <div class="rightZhyfContentLeft2" style="margin-top: 24px">
                <div class="item" @click="$refs.childDialog.openZhonggeng('咨询执业药师')">
                  <div class="item1">一键咨询执业药师数</div>
                  <div class="item2"><span class="num">15</span><span class="ren">人</span></div>
                </div>
                <div class="item" style="margin-top: 15px">
                  <div class="item1">一键问诊签约医生数</div>
                  <div class="item2"><span class="num">53</span><span class="ren">人</span></div>
                </div>
                <div class="item" style="margin-top: 10px">
                  <div class="item1">互联网医院在线问诊数</div>
                  <div class="item2"><span class="num">9</span><span class="ren">人</span></div>
                </div>
              </div>
            </div>
            <div class="rightZhyfContentRight">
              <div style="width: 318px;padding-left: 10px;padding-top: 15px">
                <el-carousel trigger="click" height="264px" indicator-position="none" :interval="7000">
                  <el-carousel-item v-for="item in aList" :key="item">
                    <el-image style="width:100%;height: 100%" :src="$getImageUrl(item)" alt=""
                      :preview-src-list="aList">
                    </el-image>
                  </el-carousel-item>
                </el-carousel>
              </div>
            </div>
          </div>
        </div>
        <!--              <div class="rightZhyf">
                <div class="title">智慧药房</div>
                <div class="zhonggeng">中埂社区智慧药房</div>
                <div class="row2">
                  <div class="item" @click="$refs.childDialog.openZhonggeng('本月销售')"><div>本月销售服务数量：</div><div><span class="num">43</span><span class="ren">人</span></div></div>
                  <div class="item" style="margin-left: 0px" @click="$refs.childDialog.openZhonggeng('处方流转')"><div>三大医院处方流转取药数:</div><div><span class="num">5</span><span class="ren">人</span></div></div>
                </div>
                <div class="row3">
                  <div class="item" @click="$refs.childDialog.openZhonggeng('咨询执业药师')">
                    <div class="item1"><span class="num">15</span><span class="ren">人</span></div>
                    <div class="item2" >一键咨询执业药师数</div>
                  </div>
                  <div class="item">
                    <div class="item1"><span class="num">7</span><span class="ren">人</span></div>
                    <div class="item2">一键问诊签约医生数</div>
                  </div>
                  <div class="item">
                    <div class="item1"><span class="num">9</span><span class="ren">人</span></div>
                    <div class="item2">互联网医院在线问诊数</div>
                  </div>
                </div>
              </div>-->
        <div class="rightYsfw">
          <div class="title">
            药事服务
          </div>
          <!--                <div class="shops">
                  <div class="item">
                    <div class="sname">浦江九色鹿国药馆</div>
                    <div class="item2">
                      <div style="width: 245px">
                        <el-carousel
                            height="141px"
                            :interval="7000"
                            indicator-position="none"
                        >
                          <el-carousel-item v-for="(item, index) in srcList" :key="index">
                            <el-image
                                style="width:100%;height:100%"
                                :src="item"
                                alt=""

                            >
                            </el-image>
                          </el-carousel-item>
                        </el-carousel>
                      </div>
                      <div class="item2-2"  @click="
                                        $refs.childDialog.openYizhan('浦江九色鹿国药馆')
                                    ">
                        <div class="num">11086</div>
                        <div class="numName">服务总量▶</div>
                      </div>
                    </div>
                  </div>
                  <div class="item">
                    <div class="sname">浙江戴德泰</div>
                    <div class="item2">
                      <div style="width: 245px">
                        <el-carousel
                            height="141px"
                            :interval="7000"
                            indicator-position="none"
                        >
                          <el-carousel-item v-for="(item, index) in imgList" :key="index">
                            <el-image
                                style="width:100%;height:100%"
                                :src="item"
                                alt=""
                                :preview-src-list="imgList"
                            >
                            </el-image>
                          </el-carousel-item>
                        </el-carousel>
                      </div>
                      <div class="item2-2" @click="$refs.childDialog.openYizhan('浙江戴德泰')">
                        <div class="num">12391</div>
                        <div class="numName">服务总量▶</div>
                      </div>
                    </div>
                  </div>
                  <div class="item">
                    <div class="sname">浦江县恒生药房</div>
                    <div class="item2">
                      <div style="width: 245px">
                        <el-carousel
                            height="141px"
                            :interval="7000"
                            indicator-position="none"
                        >
                          <el-carousel-item v-for="(item, index) in hsList" :key="index">
                            <el-image
                                style="width:100%;height:100%"
                                :src="item"
                                alt=""
                                :preview-src-list="hsList"
                            >
                            </el-image>
                          </el-carousel-item>
                        </el-carousel>
                      </div>
                      <div class="item2-2"  @click="
                                        $refs.childDialog.openYizhan(
                                            '浦江县恒生药房'
                                        )
                                    ">
                        <div class="num">6132</div>
                        <div class="numName">服务总量▶</div>
                      </div>
                    </div>
                  </div>
                  <div class="item">
                    <div class="sname">浙江康佰家</div>
                    <div class="item2">
                      <div style="width: 245px">
                        <el-carousel
                            height="141px"
                            :interval="7000"
                            indicator-position="none"
                        >
                          <el-carousel-item v-for="(item, index) in kbjList" :key="index">
                            <el-image
                                style="width:100%;height:100%"
                                :src="item"
                                alt=""
                                :preview-src-list="kbjList"
                            >
                            </el-image>
                          </el-carousel-item>
                        </el-carousel>
                      </div>
                      <div class="item2-2" @click="
                                        $refs.childDialog.openYizhan(
                                            '浙江康佰家'
                                        )
                                    ">
                        <div class="num">6352</div>
                        <div class="numName">服务总量▶</div>
                      </div>
                    </div>
                  </div>
                </div>-->
          <div class="ysBiao">
            <div class="ysBiao2">
              <table width="100%" cellspacing="0" cellpadding="0">
                <tr class="biaoTitle">
                  <td class="txt-c" style="width: 50px">序号</td>
                  <td style="padding: 0px 10px">药店名称</td>
                  <td style="width: 340px">地址</td>
                  <td style="width: 100px" class="txt-c">服务总量</td>
                </tr>
              </table>
              <div style="height:436px;overflow: hidden;">
                <vue-seamless-scroll :data="msysData" :class-option="defaultOption">
                  <table width="100%" cellspacing="0" cellpadding="0">
                    <tr class="biaoList" v-for="(x, i) in msysData" :key="i">
                      <td style="width: 50px;height: 70px" class="txt-c">
                        {{ i + 1 }}
                      </td>
                      <td style="padding: 0px 10px">{{ x.storeName }}</td>
                      <td style="width: 340px">{{ x.address }}</td>
                      <td style="width: 100px" class="txt-c">{{ x.serviceCount + '1' }}</td>
                    </tr>
                  </table>
                </vue-seamless-scroll>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
    <yun-dialog ref="childDialog" />
  </div>
</template>

<script>
/* eslint-disable */
import yunDialog from "@/views/yunDialog";
import yunHeader from "@/views/yunHeader";
import huishou from "@/assets/data/huishou.json";
import unqualifiedData from '@/assets/data/bhgyp.json'
import vueSeamlessScroll from "vue-seamless-scroll";
import jsl1 from "../assets/yun/yaoshi/jsl1.jpg";
import jsl2 from "../assets/yun/yaoshi/jsl2.jpg";
import jsl3 from "../assets/yun/yaoshi/jsl3.jpg";
import jsl4 from "../assets/yun/yaoshi/jsl4.jpg";
import jsl5 from "../assets/yun/yaoshi/jsl5.png";
import jsl6 from "../assets/yun/yaoshi/jsl6.jpg";
import ddt1 from "../assets/yun/yaoshi/ddt1.jpg";
import ddt2 from "../assets/yun/yaoshi/ddt2.jpg";
import ddt3 from "../assets/yun/yaoshi/ddt3.jpg";
import ddt4 from "../assets/yun/yaoshi/ddt4.jpg";
import ddt5 from "../assets/yun/yaoshi/ddt5.jpg";
import ddt6 from "../assets/yun/yaoshi/ddt6.jpg";
import ddt7 from "../assets/yun/yaoshi/ddt7.jpg";
import ddt8 from "../assets/yun/yaoshi/ddt8.jpg";
import hs1 from "../assets/yun/yaoshi/hs1.jpg";
import hs2 from "../assets/yun/yaoshi/hs2.jpg";
import hs3 from "../assets/yun/yaoshi/hs3.jpg";
import kbj1 from "../assets/yun/yaoshi/kbj1.jpg";
import kbj2 from "../assets/yun/yaoshi/kbj2.jpg";
import kbj3 from "../assets/yun/yaoshi/kbj3.jpg";
import kbj4 from "../assets/yun/yaoshi/kbj4.jpg";
import kbj5 from "../assets/yun/yaoshi/kbj5.jpg";
import kbj6 from "../assets/yun/yaoshi/kbj6.jpg";
import kbj7 from "../assets/yun/yaoshi/kbj7.jpg";
import a1 from "../assets/yun/yaoshi/a1.jpg";
import a2 from "../assets/yun/yaoshi/a2.jpg";
import a3 from "../assets/yun/yaoshi/a3.jpg";
import a4 from "../assets/yun/yaoshi/a4.jpg";
import a5 from "../assets/yun/yaoshi/a5.jpg";
import a6 from "../assets/yun/yaoshi/a6.jpg";
import { visitor, pharmacyServiceStation } from "@/api/yaojian";
export default {
  name: "yunYaoshi",
  components: { yunHeader, vueSeamlessScroll, yunDialog },
  data() {
    return {
      postYaodian: {
        page: 1,
        count: 10,
        total: 0,
        type: 70
      },
      huiCount: 0,
      huishou: huishou,
      aList: [a1, a2, a3, a4, a5, a6],
      srcList: [jsl1, jsl2, jsl3, jsl4, jsl5, jsl6],
      imgList: [ddt1, ddt2, ddt3, ddt4, ddt5, ddt6, ddt7, ddt8],
      hsList: [hs1, hs2, hs3],
      kbjList: [kbj1, kbj2, kbj3, kbj4, kbj5, kbj6, kbj7],
      unqualifiedData: unqualifiedData,
      msysData: []
    };
  },
  computed: {
    defaultOption() {
      return {
        step: 0.5, // 数值越大速度滚动越快
        limitMoveNum: 6, // 开始无缝滚动的数据量 this.dataList.length
        hoverStop: true, // 是否开启鼠标悬停stop
        direction: 1, // 0向下 1向上 2向左 3向右
        openWatch: true, // 开启数据实时监控刷新dom
        singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
        singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
        waitTime: 1000, // 单步运动停止的时间(默认值1000ms)
      };
    },
  },
  created() {
    this.getYaodian()
    this.loadStoreDeliveryData()
  },
  methods: {
    loadStoreDeliveryData() {
      pharmacyServiceStation({ page: 1, count: 200 }).then(res => {
        this.msysData = res.data.list
      })
    },
    async getYaodian() {
      let rs = await visitor(this.postYaodian)
      if (rs.code == 200 && rs.data.list) {
        this.huishou = rs.data.list
        this.huiCount = rs.data.total
      }
    }
  }
};
</script>

<style scoped lang="less">
@color: #00d7fe;

.rightZhyfContent {
  margin-top: 26px;
  display: flex;

  .rightZhyfContentLeft {
    width: 500px;
    padding-left: 20px;

    .rightZhyfContentLeft1 {
      display: flex;

      .item {
        width: 240px;
        height: 72px;
        color: #00D7FE;
        font-size: 19px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;

        .num {
          font-size: 36px;
          font-family: Source Han Sans CN;
          font-weight: bold;
          color: #FBC902;
        }

        .ren {
          font-size: 20px;
          font-family: Source Han Sans CN;
          font-weight: bold;
          color: #FBC902;
        }
      }

      .rightZhyfContentLeft1Right {
        margin-left: 20px;
      }
    }
  }

  .rightZhyfContentLeft2 {
    .item {
      padding: 0 20px;
      display: flex;
      height: 60px;
      align-items: center;

      .item1 {
        flex: 1;
        color: @color;
        font-size: 19px
      }

      .item2 {
        color: #fff;

        .num {
          font-size: 30px;
        }

        .ren {
          font-size: 14px;
        }
      }
    }
  }

  .rightZhyfContentRight {
    width: 350px;
    margin-left: 20px;
  }
}

.rightZhyf {
  background-image: url("../assets/yun/yaoshi/a1.png");
  width: 899px;
  height: 393px;

  .title {
    letter-spacing: 3px
  }

  .zhonggeng {
    padding-top: 10px;
    float: right;
    padding-right: 12px;
    font-size: 24px;
    font-family: Source Han Sans CN;
    font-weight: bold;
    color: #FFFFFF;
  }

  .row2 {
    margin-left: 5px;
    margin-top: 20px;
    display: flex;

    .item {
      width: 400px;
      height: 70px;
      color: #00D7FE;
      font-size: 24px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 20px;

      .num {
        font-size: 36px;
        font-family: Source Han Sans CN;
        font-weight: bold;
        color: #FBC902;
      }

      .ren {
        font-size: 20px;
        font-family: Source Han Sans CN;
        font-weight: bold;
        color: #FBC902;
      }
    }
  }

  .row3 {
    margin-top: 23px;
    display: flex;
    align-items: center;

    .item {
      margin-left: 20px;
      text-align: center;
      width: 275px;
      height: 155px;
      display: flex;
      flex-direction: column;
      justify-content: center;

      .item1 {
        .num {
          font-size: 36px;
          font-family: Source Han Sans CN;
          font-weight: bold;
          color: #FFFFFF;
        }

        .ren {
          font-size: 20px;
          font-family: Source Han Sans CN;
          color: #FFFFFF;
        }
      }

      .item2 {
        margin-top: 15px;
        color: #00D7FE;
      }
    }
  }
}

.rightYsfw {
  .shops {
    display: flex;
    flex-wrap: wrap;
  }

  .title {
    letter-spacing: 3px
  }

  background-image: url("../assets/yun/yaoshi/a2.png");
  width: 899px;
  height: 551px;
  margin-top: 15px;

  .item {
    width: 420px;
    height: 226px;
    margin-left: 20px;
    margin-top: 20px;

    .sname {
      line-height: 55px;
      padding-left: 30px;
      font-size: 24px;
      font-family: Source Han Sans CN;
      font-weight: bold;
      color: #FFFFFF;
    }

    .num {
      font-size: 36px;
      font-family: Source Han Sans CN;
      font-weight: bold;
      color: #FBC902;
    }

    .numName {
      font-size: 20px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #00D7FE;
    }

    .item2 {
      display: flex;
      padding-left: 20px;
      margin-top: 9px;

      .item2-2 {
        width: 130px;
        height: 141px;
        margin-left: 11px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
      }
    }
  }
}

.rightBox .item2-2 {
  display: flex;
}

.rightBox .item2 {
  width: 368px;
  height: 337px;
  margin-top: 20px;
  padding: 10px;
}

.rightBox .item2-2-1 {
  flex: 1;
  font-size: 26px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #00d7fe;
}

.rightBox .item2-2-2 {
  font-size: 32px;
  font-family: Source Han Sans CN;
  font-weight: bold;
  color: #fbc902;
}

.rightBox .item2-1 {
  height: 239px;
  line-height: 157%;
  padding: 20px 0px;
  font-size: 22px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #ffffff;
}

.rightBox .item1 {
  width: 399px;
  line-height: 41px;
  text-align: center;
  font-size: 27px;
  font-family: Source Han Sans CN;
  font-weight: bold;
  color: #00d7fe;
}

.rightBox {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 0 30px;
}

.rightBox .itemC {
  padding: 5px 13px;
}

.rightBox .item {
  margin-top: 30px;
  background-image: url("../assets/yun/yaoshi/right-box.png");
  width: 410px;
  height: 409px;
}

.txt-c {
  text-align: center;
}

.hsBiao .biaoTitle td {
  font-size: 18px;
  color: #ffffff;
  background-color: rgba(2, 119, 250, 0.3);
  line-height: 40px;
}

.hsBiao .biaoList td {
  font-size: 18px;
  color: #ffffff;
  height: 40px;
}

.hsBiao .biaoList:nth-child(odd) {
  background-color: rgba(2, 119, 250, 0.1);
}

.hsBiao {
  padding: 0px 20px;
  height: auto;
  overflow: hidden;
}

.content {
  height: 510px;
  width: 100%;
  padding: 20px 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.content .top {
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.content .bottom {
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.bottom-commont {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}

.bottom-commont span {
  font-size: 20px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #00d7fe;
}

.top-center {
  width: 302px;
  height: 296px;
  background-image: url("../assets/yun/yaoshi/center-yaoshi.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  font-size: 26px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #00d7fe;
  display: flex;
  justify-content: center;
  align-items: center;
}

.top-center span {
  position: relative;
  top: 45px;
}

.top-left {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.top-left span {
  font-size: 20px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #fbc902;
  text-align: center;
}

.top-leftbg {
  width: 126px;
  height: 127px;
  background-image: url("../assets/yun/yaoshi/yellow-circler.gif");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 26px;
  font-family: Source Han Sans CN;
  font-weight: bold;
  color: #fbc902;
}

.bottom-bg {
  width: 126px;
  height: 127px;
  background-image: url("../assets/yun/yaoshi/blue-circler.gif");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 26px;
  font-family: Source Han Sans CN;
  font-weight: bold;
  color: #00d7fe;
}

.yphsjd {
  width: 889px;
  height: 39px;
  display: flex;
  line-height: 36px;
  margin-top: 15px;
  background-image: url("../assets/yun/yaoshi/jb.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  margin: 0 auto;
}

.yphsjd .item1 {
  flex: 1;
  font-size: 24px;
  font-family: Source Han Sans CN;
  font-weight: bold;
  color: #ffffff;
}

.yphsjd .item2 {
  font-size: 24px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #00d7fe;
  margin-right: 10px;
}

.yphsjd .item3 {
  font-size: 36px;
  font-family: Source Han Sans CN;
  font-weight: bold;
  color: #fffffe;
}

.yxsjBox {
  display: flex;
  justify-content: space-between;
  padding: 0px 20px;
  margin-top: 20px;
}

.yxsjBox .itemC {
  padding-top: 5px;
  display: flex;
  width: 100%;
}

.yxsjBox .item {
  display: flex;
}

.yxsjBox .item1 {
  padding-left: 10px;
  flex: 1;
  font-size: 24px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #00d7fe;
  line-height: 68px;
}

.yxsjBox .item2 {
  padding-right: 10px;
  font-size: 36px;
  font-family: Source Han Sans CN;
  font-weight: bold;
  color: #fbc902;
  line-height: 68px;
}

.yxsjBox .item {
  background-image: url("../assets/yun/yaoshi/leftbox2.png");
  width: 444px;
  height: 79px;
}

.yxtxBox .item1 {
  font-size: 36px;
  font-family: Source Han Sans CN;
  font-weight: bold;
  color: #ffffff;
}

.yxtxBox .item2 {
  font-size: 24px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #00d7fe;
}

.yxtxBox .item {
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  background-image: url("../assets/yun/yaoshi/left-box.png");
  width: 285px;
  height: 155px;
}

.yxtxBox {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  padding: 0 20px;
}

.yxtx {
  margin-top: 17px;
  margin-left: 41px;
  width: 889px;
  height: 39px;
  line-height: 39px;
  font-size: 24px;
  font-family: Source Han Sans CN;
  font-weight: bold;
  color: #ffffff;
}

.title {
  font-size: 28px;
  font-family: Source Han Sans CN;
  font-weight: bold;
  color: #00d7fe;
  padding-top: 9px;
  padding-left: 19px;
}

.main {
  width: 1920px;
  height: 1080px;
  overflow: hidden;
  background-image: url("../assets/store/bg.jpg");
}

.body {
  padding: 0px 35px;
  margin-top: 10px;
}

.body_left {
  margin-right: 20px;
  background-image: url("../assets/yun/yaoshi/left-1.png");
  width: 930px;
  height: 959px;
  display: flex;
  flex-direction: column;
  background-size: 100% 100%;
}

.body_right {
  //background-image: url("../assets/yun/yaoshi/right-1.png");
  width: 900px;
  height: 959px;
}

.body {
  display: flex;
}

.ysBiao {
  margin-top: 15px;
  color: #FFFFFF;
  padding-left: 12px;
  width: 98%;

  .ysBiao2 {
    width: 100%;
    height: 483px;
    background-color: #071034;

    .biaoTitle {
      //font-weight: bolder;
      background-color: #062f6f;
      line-height: 40px;
      font-size: 18px;
    }
  }
}
</style>
